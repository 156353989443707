.resources-component-accordion {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.resources-component-element {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.resources-component-details {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.resources-component-text {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.resources-component-container {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-halfunit);
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.resources-component-link {
  display: contents;
}
.resources-component-image {
  width: 112px;
  height: 154px;
  padding: var(--dl-space-space-halfunit);
  object-fit: cover;
  text-decoration: none;
}
.resources-component-link01 {
  display: contents;
}
.resources-component-image1 {
  width: 123px;
  height: 157px;
  padding: var(--dl-space-space-halfunit);
  object-fit: cover;
  text-decoration: none;
}
.resources-component-link02 {
  display: contents;
}
.resources-component-image2 {
  width: 120px;
  height: 156px;
  padding: var(--dl-space-space-halfunit);
  object-fit: cover;
  text-decoration: none;
}
.resources-component-link03 {
  display: contents;
}
.resources-component-image3 {
  width: 119px;
  height: 162px;
  padding: var(--dl-space-space-halfunit);
  object-fit: cover;
  text-decoration: none;
}
.resources-component-link04 {
  display: contents;
}
.resources-component-image4 {
  width: 119px;
  height: 164px;
  padding: var(--dl-space-space-halfunit);
  object-fit: cover;
  text-decoration: none;
}
.resources-component-link05 {
  display: contents;
}
.resources-component-image5 {
  width: 131px;
  height: 162px;
  padding: var(--dl-space-space-halfunit);
  object-fit: cover;
  text-decoration: none;
}
.resources-component-link06 {
  display: contents;
}
.resources-component-image6 {
  width: 112px;
  height: 149px;
  padding: var(--dl-space-space-halfunit);
  object-fit: cover;
  text-decoration: none;
}
.resources-component-link07 {
  display: contents;
}
.resources-component-image7 {
  width: 110px;
  height: 157px;
  padding: var(--dl-space-space-halfunit);
  object-fit: cover;
  text-decoration: none;
}
.resources-component-element1 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.resources-component-details1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.resources-component-text01 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.resources-component-text02 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}
.resources-component-link08 {
  text-decoration: underline;
}
.resources-component-link09 {
  text-decoration: underline;
}
.resources-component-element2 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.resources-component-details2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.resources-component-text17 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.resources-component-text18 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}
.resources-component-link10 {
  text-decoration: underline;
}
.resources-component-link11 {
  text-decoration: underline;
}
.resources-component-element3 {
  width: 100%;
  max-width: 800px;
  padding-top: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
}
.resources-component-details3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.resources-component-text31 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  line-height: 28px;
}
.resources-component-text32 {
  color: rgb(204, 204, 204);
  width: 100%;
  font-family: Poppins;
  line-height: 28px;
  user-select: text;
}
.resources-component-link12 {
  text-decoration: underline;
}

@media(max-width: 767px) {
  .resources-component-text {
    font-size: 16px;
    line-height: 24px;
  }
  .resources-component-text01 {
    font-size: 16px;
    line-height: 24px;
  }
  .resources-component-text17 {
    font-size: 16px;
    line-height: 24px;
  }
  .resources-component-text31 {
    font-size: 16px;
    line-height: 24px;
  }
}
