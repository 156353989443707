.about-container {
  width: 100%;
  display: flex;
  overflow: auto;
  max-width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  background-color: #242d36;
}
.about-section1 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: 0px;
  justify-content: center;
}
.about-note {
  gap: 140px;
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.about-content {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 520px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.about-main {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-heading {
  color: rgb(255, 255, 255);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.about-paragraph {
  color: rgb(204, 204, 204);
  font-family: Poppins;
  line-height: 28px;
}
.about-text04 {
  color: #cccccc;
  font-family: Poppins;
  line-height: 28px;
}
.about-text07 {
  color: #cccccc;
  font-family: Poppins;
  line-height: 28px;
}
.about-image {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.about-image1 {
  width: 384px;
  height: 498px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.about-section2 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.about-note1 {
  gap: 140px;
  display: flex;
  max-width: 1200px;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.about-image2 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.about-image3 {
  width: 543px;
  height: 405px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.about-content1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.about-main1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-heading1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.about-paragraph1 {
  color: rgb(204, 204, 204);
  font-family: Poppins;
  line-height: 28px;
}
.about-explore-more {
  gap: var(--dl-space-space-oneandhalfunits);
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: #6d942d;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  border-bottom-width: 1px;
}
.about-explore-more:hover {
  opacity: 0.5;
}
.about-link {
  display: contents;
}
.about-text15 {
  color: rgb(109, 148, 45);
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.about-image4 {
  width: 543px;
  height: 405px;
  display: none;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.about-section3 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  justify-content: center;
}
.about-note2 {
  gap: 140px;
  display: flex;
  max-width: 1200px;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.about-content2 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.about-main2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-heading2 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.about-paragraph2 {
  color: rgb(204, 204, 204);
  font-family: Poppins;
  line-height: 28px;
}
.about-explore-more1 {
  gap: var(--dl-space-space-oneandhalfunits);
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: #6d942d;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  border-bottom-width: 1px;
}
.about-explore-more1:hover {
  opacity: 0.5;
}
.about-link1 {
  display: contents;
}
.about-text18 {
  color: rgb(109, 148, 45);
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.about-image5 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.about-image6 {
  width: 554px;
  height: 343px;
  border-radius: var(--dl-radius-radius-radius8);
}
.about-section4 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.about-note3 {
  gap: 140px;
  display: flex;
  max-width: 1200px;
  align-self: center;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.about-image7 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}
.about-image8 {
  width: 555px;
  height: 340px;
  object-fit: cover;
}
.about-content3 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.about-main3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-heading3 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.about-paragraph3 {
  color: rgb(204, 204, 204);
  font-family: Poppins;
  line-height: 28px;
}
.about-explore-more2 {
  gap: var(--dl-space-space-oneandhalfunits);
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: #6d942d;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  border-bottom-width: 1px;
}
.about-explore-more2:hover {
  opacity: 0.5;
}
.about-link2 {
  display: contents;
}
.about-text24 {
  color: rgb(109, 148, 45);
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.about-footer {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
@media(max-width: 1200px) {
  .about-section1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .about-image6 {
    width: 575px;
    height: 419px;
  }
  .about-heading3 {
    color: rgb(255, 255, 255);
    font-size: 32px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .about-paragraph3 {
    color: rgb(204, 204, 204);
  }
  .about-text24 {
    color: rgb(109, 148, 45);
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}
@media(max-width: 991px) {
  .about-note {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .about-content {
    max-width: 100%;
  }
  .about-main {
    align-items: flex-start;
  }
  .about-note1 {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .about-image3 {
    display: none;
  }
  .about-main1 {
    align-items: flex-start;
  }
  .about-image4 {
    display: inline;
  }
  .about-note2 {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .about-main2 {
    align-items: flex-start;
  }
  .about-image6 {
    display: none;
  }
  .about-note3 {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .about-main3 {
    align-items: flex-start;
  }
}
@media(max-width: 767px) {
  .about-section1 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .about-note {
    gap: var(--dl-space-space-threeunits);
  }
  .about-main {
    width: 100%;
    align-items: flex-start;
  }
  .about-heading {
    font-size: 28px;
  }
  .about-paragraph {
    line-height: 24px;
  }
  .about-note1 {
    gap: var(--dl-space-space-threeunits);
  }
  .about-main1 {
    width: 100%;
    align-items: flex-start;
  }
  .about-heading1 {
    font-size: 28px;
  }
  .about-text09 {
    font-size: 28px;
  }
  .about-paragraph1 {
    line-height: 24px;
  }
  .about-image4 {
    display: inline;
  }
  .about-section3 {
    padding-top: 100px;
  }
  .about-note2 {
    gap: var(--dl-space-space-threeunits);
  }
  .about-main2 {
    width: 100%;
    align-items: flex-start;
  }
  .about-heading2 {
    font-size: 28px;
  }
  .about-text16 {
    font-size: 28px;
  }
  .about-paragraph2 {
    line-height: 24px;
  }
  .about-image5 {
    display: none;
  }
  .about-note3 {
    gap: var(--dl-space-space-threeunits);
  }
  .about-main3 {
    width: 100%;
    align-items: flex-start;
  }
  .about-heading3 {
    font-size: 28px;
  }
  .about-text19 {
    font-size: 28px;
  }
  .about-paragraph3 {
    line-height: 24px;
  }
}
@media(max-width: 479px) {
  .about-section1 {
    padding-top: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .about-image1 {
    width: 337px;
    height: 424px;
  }
  .about-image2 {
    width: auto;
  }
  .about-content1 {
    width: auto;
  }
  .about-main1 {
    width: 100%;
  }
  .about-image4 {
    width: 340px;
    height: 237px;
  }
  .about-section3 {
    position: relative;
  }
  .about-heading2 {
    color: rgb(255, 255, 255);
    font-size: 28px;
    font-style: normal;
    font-family: Poppins;
    font-weight: 500;
  }
  .about-text16 {
    font-size: 28px;
  }
  .about-paragraph2 {
    color: rgb(204, 204, 204);
    font-family: Poppins;
    line-height: 24px;
  }
  .about-note3 {
    width: 100%;
    max-width: 100%;
  }
  .about-image8 {
    width: 354px;
    height: 244px;
    border-radius: var(--dl-radius-radius-radius8);
  }
  .about-main3 {
    max-width: 100%;
  }
}
