.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  background-color: #242d36;
}
.home-section1 {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: center;
}
.home-hero {
  gap: var(--dl-space-space-threeunits);
  width: 909px;
  display: flex;
  max-width: 1200px;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-start;
}
.home-image {
  width: 300px;
  height: 485px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-content {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
}
.home-main {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 64px;
  font-style: normal;
  font-family: Arial;
  font-weight: 600;
}
.home-text {
  color: rgb(255, 255, 255);
  font-size: 20px;
  font-family: Poppins;
  line-height: 30px;
}
.home-navlink {
  display: contents;
}
.home-component1 {
  text-decoration: none;
}
.home-section2 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.home-note {
  gap: 140px;
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-content1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 520px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-main1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-heading1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.home-paragraph {
  color: rgb(204, 204, 204);
  font-family: Poppins;
  line-height: 28px;
}
.home-text09 {
  color: #cccccc;
  font-family: Poppins;
  line-height: 28px;
}
.home-text12 {
  color: #cccccc;
  font-family: Poppins;
  line-height: 28px;
}
.home-explore-more {
  gap: var(--dl-space-space-oneandhalfunits);
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  border-color: #6d942d;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: center;
  border-bottom-width: 1px;
}
.home-explore-more:hover {
  opacity: 0.5;
}
.home-navlink1 {
  display: contents;
}
.home-text16 {
  color: rgb(109, 148, 45);
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.home-image1 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.home-image2 {
  width: 607px;
  height: 478px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.home-latest-news {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-header {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-left {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-section {
  color: rgb(109, 148, 45);
}
.home-heading2 {
  text-align: center;
}
.home-iframe {
  width: 501px;
  height: 558px;
  align-self: center;
}
.home-testimonials {
  gap: var(--dl-space-space-twounits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
}
.home-header1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-header2 {
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
}
.home-text17 {
  color: rgb(109, 148, 45);
  padding-bottom: var(--dl-space-space-twounits);
}
.home-heading3 {
  text-align: center;
}
.home-cards {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-threeunits);
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  grid-template-columns: auto auto auto;
}
.home-container1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-container2 {
  gap: var(--dl-space-space-threeunits);
  height: 730px;
  display: flex;
  flex-direction: column;
}
.home-container3 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.home-navlink2 {
  display: contents;
}
.home-more-testimonials {
  position: relative;
  text-decoration: none;
  background-color: #6d942d;
}
.home-text20 {
  color: rgb(255, 255, 255);
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 1200px) {
  .home-image {
    width: 287px;
    height: 433px;
  }
  .home-section2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-testimonials {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 991px) {
  .home-section1 {
    height: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-hero {
    gap: var(--dl-space-space-fourunits);
    flex: 1;
    position: relative;
    max-width: auto;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .home-image {
    width: 294px;
    height: 389px;
  }
  .home-content {
    position: relative;
    align-items: center;
  }
  .home-main {
    align-items: center;
  }
  .home-heading {
    text-align: center;
  }
  .home-text {
    text-align: center;
  }
  .home-text01 {
    text-align: left;
  }
  .home-text02 {
    text-align: left;
  }
  .home-text03 {
    text-align: left;
  }
  .home-note {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .home-content1 {
    max-width: 100%;
  }
  .home-main1 {
    align-items: flex-start;
  }
  .home-image1 {
    display: none;
  }
  .home-cards {
    grid-template-columns: auto;
  }
  .home-container3 {
    display: none;
  }
}
@media(max-width: 767px) {
  .home-content {
    gap: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading {
    font-size: 40px;
  }
  .home-text {
    color: rgb(255, 255, 255);
  }
  .home-text01 {
    color: #ffffff;
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
  }
  .home-text02 {
    color: #ffffff;
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
  }
  .home-text03 {
    color: #ffffff;
    font-size: 16px;
    font-family: Poppins;
    line-height: 24px;
  }
  .home-section2 {
    height: 1162px;
    padding-top: 100px;
    margin-bottom: 0px;
    padding-bottom: 100px;
  }
  .home-note {
    gap: var(--dl-space-space-threeunits);
  }
  .home-main1 {
    width: 100%;
    align-items: flex-start;
  }
  .home-heading1 {
    font-size: 28px;
  }
  .home-paragraph {
    line-height: 24px;
  }
  .home-image1 {
    display: none;
  }
  .home-more-testimonials {
    display: none;
  }
}
@media(max-width: 479px) {
  .home-image {
    width: 195px;
    height: 276px;
  }
  .home-main {
    max-width: 100%;
  }
  .home-heading {
    max-width: 280px;
  }
  .home-text {
    max-width: 100%;
  }
  .home-image1 {
    display: none;
  }
}
