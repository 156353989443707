.page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  background-color: #242d36;
}
.page-section2 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
}
.page-note {
  gap: 140px;
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.page-content {
  gap: var(--dl-space-space-threeunits);
  width: 256px;
  display: flex;
  max-width: 520px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.page-main {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.page-heading {
  color: rgb(255, 255, 255);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.page-navlink {
  display: contents;
}
.page-paragraph {
  color: rgb(204, 204, 204);
  font-family: Poppins;
  line-height: 28px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .page-note {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .page-content {
    max-width: 100%;
  }
  .page-main {
    align-items: flex-start;
  }
}
@media(max-width: 767px) {
  .page-section2 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .page-note {
    gap: var(--dl-space-space-threeunits);
  }
  .page-main {
    width: 100%;
    align-items: flex-start;
  }
  .page-heading {
    font-size: 28px;
  }
  .page-paragraph {
    line-height: 24px;
  }
}
