.footer-footer {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: 0px;
  padding-left: 00px;
  padding-right: 00px;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
  background-color: #ffffff;
}
.footer-green-break {
  flex: 0 0 auto;
  width: 100%;
  height: 15px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
  background-color: #6D942D;
}
.footer-content {
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-main-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-content1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.footer-main {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.footer-header {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.footer-branding {
  width: 120px;
  object-fit: cover;
}
.footer-text {
  color: #000000;
  width: 100%;
  font-size: 14px;
  max-width: 260px;
  font-family: Poppins;
  line-height: 21px;
}
.footer-socials {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.footer-categories {
  gap: var(--dl-space-space-fourunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.footer-category {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.footer-header1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.footer-text01 {
  color: #000000;
  text-decoration: none;
}
.footer-links {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text02 {
  color: #000000;
  text-decoration: none;
}
.footer-text03 {
  color: #000000;
  text-decoration: none;
}
.footer-text04 {
  color: #000000;
  text-decoration: none;
}
.footer-text05 {
  color: #000000;
  text-decoration: none;
}
.footer-category1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 175px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.footer-header2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.footer-text06 {
  color: #000000;
}
.footer-links1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.footer-text07 {
  color: #000000;
  text-decoration: none;
}
.footer-text08 {
  color: #000000;
  text-decoration: none;
}
.footer-text09 {
  color: #000000;
  text-decoration: none;
}
.footer-text10 {
  color: #000000;
  text-decoration: none;
}
.footer-text11 {
  color: #000000;
  text-decoration: none;
}
.footer-copyright {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.footer-text12 {
  color: rgb(196, 196, 196);
  width: 100%;
  font-size: 14px;
  font-family: Poppins;
  line-height: 21px;
}






@media(max-width: 1200px) {
  .footer-content {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 991px) {
  .footer-content {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .footer-copyright {
    display: none;
  }
}
@media(max-width: 767px) {
  .footer-content1 {
    flex-direction: column;
  }
  .footer-main {
    gap: var(--dl-space-space-twounits);
  }
  .footer-categories {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .footer-content1 {
    padding-left: var(--dl-space-space-halfunit);
  }
}
