.navbar-navbar {
  width: 100%;
  height: 102px;
  display: flex;
  padding: 0px;
  z-index: 1000;
  position: relative;
  max-width: 100%;
  align-self: auto;
  min-height: auto;
  flex-shrink: 1;
  flex-direction: column;
}
.navbar-navbar-interactive {
  width: 100%;
  height: 116px;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
}
.navbar-container {
  flex: 0 0 auto;
  width: 75px;
  height: 75px;
  display: flex;
  max-width: 75;
  max-height: 75;
  align-items: flex-start;
}
.navbar-navlink {
  display: contents;
}
.navbar-logo {
  width: 75px;
  height: 75px;
  max-width: 75px;
  align-self: initial;
  max-height: 75px;
}
.navbar-desktop-menu {
  display: flex;
}
.navbar-nav-content {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-direction: row;
  justify-content: flex-start;
}
.navbar-nav-links {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  border-color: transparent;
}
.navbar-link {
  color: #000000;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}
.navbar-link1 {
  color: rgb(0, 0, 0);
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}
.navbar-link2 {
  color: rgb(0, 0, 0);
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}
.navbar-link3 {
  color: rgb(0, 0, 0);
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}
.navbar-link4 {
  color: rgb(0, 0, 0);
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}
.navbar-navlink1 {
  display: contents;
}
.navbar-contact-gina {
  position: relative;
  text-decoration: none;
  background-color: #6d942d;
}
.navbar-text2 {
  color: #ffffff;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  font-stretch: normal;
  text-decoration: none;
}
.navbar-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.navbar-image {
  width: 100px;
  object-fit: cover;
}
.navbar-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.navbar-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.navbar-image1 {
  max-width: 75px;
  max-height: 75px;
}
.navbar-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.navbar-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.navbar-navlink2 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-text3 {
  margin-bottom: var(--dl-space-space-unit);
}
.navbar-navlink3 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-navlink4 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-navlink5 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-navlink6 {
  margin-bottom: var(--dl-space-space-unit);
  text-decoration: none;
}
.navbar-icon-group {
  display: flex;
}
.navbar-link5 {
  display: contents;
}
.navbar-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}
.navbar-green-break {
  flex: 0 0 auto;
  width: 100%;
  height: 15px;
  display: flex;
  align-self: flex-end;
  flex-direction: row;
  justify-content: flex-start;
  background-color: #6D942D;
}
.navbar-root-class-name {
  background-color: #ffffff;
}

.navbar-root-class-name2 {
  background-color: #ffffff;
}
.navbar-root-class-name3 {
  background-color: #ffffff;
}
.navbar-root-class-name4 {
  background-color: #ffffff;
}
.navbar-root-class-name5 {
  background-color: #ffffff;
}
.navbar-root-class-name6 {
  background-color: #ffffff;
}
.navbar-root-class-name7 {
  background-color: #ffffff;
}
.navbar-root-class-name8 {
  background-color: #ffffff;
}
@media(max-width: 1200px) {
  .navbar-navbar-interactive {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 991px) {
  .navbar-link {
    font-style: normal;
    font-weight: 600;
  }
  .navbar-link1 {
    font-style: normal;
    font-weight: 600;
  }
  .navbar-link2 {
    font-style: normal;
    font-weight: 600;
  }
  .navbar-link3 {
    font-style: normal;
    font-weight: 600;
  }
  .navbar-link4 {
    font-style: normal;
    font-weight: 600;
  }
}
@media(max-width: 767px) {
  .navbar-navbar {
    height: 103px;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .navbar-navbar-interactive {
    height: 131px;
  }
  .navbar-desktop-menu {
    display: none;
  }
  .navbar-nav-links {
    display: none;
  }
  .navbar-contact-gina {
    display: none;
  }
  .navbar-burger-menu {
    display: flex;
  }
  .navbar-image {
    width: 16px;
    height: 14px;
  }
}
@media(max-width: 479px) {
  .navbar-mobile-menu {
    padding: 16px;
  }
}
