.contact-gina-contact-gina {
  position: relative;
  text-decoration: none;
  background-color: #6d942d;
}
.contact-gina-text {
  color: #ffffff;
  align-self: auto;
  font-style: Medium;
  text-align: left;
  font-family: Poppins;
  font-weight: 500;
  font-stretch: normal;
  text-decoration: none;
}







