.client-feedback-container {
  width: 100%;
  display: flex;
  overflow: auto;
  max-width: 100%;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #242d36;
}
.client-feedback-testimonials {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: 50px;
  justify-content: center;
}
.client-feedback-header {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.client-feedback-left {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.client-feedback-section {
  color: #6d942d;
}
.client-feedback-heading {
  text-align: center;
}
.client-feedback-cards {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-threeunits);
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  grid-template-columns: auto auto auto;
}
.client-feedback-container1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.client-feedback-container2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.client-feedback-container3 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  flex-direction: column;
}
.client-feedback-section-first {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.client-feedback-note {
  gap: 140px;
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.client-feedback-content {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.client-feedback-main {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.client-feedback-heading1 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.client-feedback-paragraph {
  color: rgb(204, 204, 204);
  font-family: Poppins;
  line-height: 28px;
}
.client-feedback-text01 {
  color: #cccccc;
  font-family: Poppins;
  line-height: 28px;
}
.client-feedback-image {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.client-feedback-image1 {
  width: 540px;
  height: 670px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
.client-feedback-section2 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}
.client-feedback-note1 {
  gap: 140px;
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.client-feedback-image2 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.client-feedback-image3 {
  width: 544px;
  height: 889px;
  align-self: flex-start;
  object-fit: cover;
}
.client-feedback-content1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.client-feedback-main1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.client-feedback-heading2 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.client-feedback-paragraph1 {
  color: rgb(204, 204, 204);
  font-family: Poppins;
  line-height: 28px;
}
.client-feedback-text05 {
  color: #cccccc;
  font-family: Poppins;
  line-height: 28px;
}
.client-feedback-text08 {
  color: #cccccc;
  font-family: Poppins;
  line-height: 28px;
}
.client-feedback-text11 {
  color: #cccccc;
  font-family: Poppins;
  line-height: 28px;
}
.client-feedback-text14 {
  color: #cccccc;
  font-family: Poppins;
  line-height: 28px;
}
.client-feedback-text17 {
  color: #cccccc;
  font-family: Poppins;
  line-height: 28px;
}
.client-feedback-text20 {
  color: #cccccc;
  font-family: Poppins;
  line-height: 28px;
}
.client-feedback-section1 {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
}
.client-feedback-banner {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  z-index: 50;
  max-width: 1200px;
  align-items: center;
  border-radius: 20px;
  justify-content: space-between;
  background-color: #292929;
}
.client-feedback-header1 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
@media(max-width: 1200px) {
  .client-feedback-section-first {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .client-feedback-section2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 991px) {
  .client-feedback-testimonials {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .client-feedback-cards {
    grid-template-columns: auto;
  }
  .client-feedback-container3 {
    display: none;
  }
  .client-feedback-section-first {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .client-feedback-note {
    gap: var(--dl-space-space-fourunits);
    align-items: center;
    flex-direction: column;
  }
  .client-feedback-content {
    max-width: 100%;
  }
  .client-feedback-main {
    align-items: flex-start;
  }
  .client-feedback-image1 {
    width: 444px;
    height: 367px;
  }
  .client-feedback-section2 {
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .client-feedback-note1 {
    gap: var(--dl-space-space-fourunits);
    align-items: center;
    flex-direction: column;
  }
  .client-feedback-image2 {
    display: none;
  }
  .client-feedback-image3 {
    align-self: flex-start;
  }
  .client-feedback-content1 {
    max-width: 100%;
  }
  .client-feedback-main1 {
    align-items: flex-start;
  }
  .client-feedback-banner {
    gap: var(--dl-space-space-twounits);
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .client-feedback-heading3 {
    align-self: center;
    text-align: center;
  }
}
@media(max-width: 767px) {
  .client-feedback-testimonials {
    padding-top: var(--dl-space-space-fourunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .client-feedback-section-first {
    padding-top: 100px;
  }
  .client-feedback-note {
    gap: var(--dl-space-space-threeunits);
  }
  .client-feedback-main {
    width: 100%;
    align-items: flex-start;
  }
  .client-feedback-heading1 {
    font-size: 28px;
  }
  .client-feedback-paragraph {
    line-height: 24px;
  }
  .client-feedback-section2 {
    padding-bottom: 100px;
  }
  .client-feedback-note1 {
    gap: var(--dl-space-space-threeunits);
  }
  .client-feedback-main1 {
    width: 100%;
    align-items: flex-start;
  }
  .client-feedback-heading2 {
    font-size: 28px;
  }
  .client-feedback-paragraph1 {
    line-height: 24px;
  }
  .client-feedback-banner {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 479px) {
  .client-feedback-section-first {
    max-width: 100%;
  }
  .client-feedback-image {
    max-width: 100%;
  }
}
