.contact-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  overflow-x: hidden;
  align-items: center;
  flex-direction: column;
  background-color: #242d36;
}
.contact-section2 {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
}
.contact-note {
  gap: 140px;
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.contact-content {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 520px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.contact-main {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.contact-heading {
  color: rgb(255, 255, 255);
  font-size: 32px;
  align-self: flex-start;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
}
.contact-paragraph {
  color: rgb(204, 204, 204);
  font-family: Poppins;
  line-height: 28px;
}
.contact-text {
  font-weight: 700;
}
.contact-text3 {
  font-weight: 700;
}
.contact-link1 {
  text-decoration: underline;
}
.contact-image {
  width: 231px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
}
.contact-image1 {
  width: 334px;
  height: 518px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-radius8);
}
@media(max-width: 991px) {
  .contact-note {
    gap: var(--dl-space-space-fourunits);
    flex-direction: column;
  }
  .contact-content {
    max-width: 100%;
  }
  .contact-main {
    align-items: flex-start;
  }
  .contact-image {
    width: 334px;
  }
}
@media(max-width: 767px) {
  .contact-section2 {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .contact-note {
    gap: var(--dl-space-space-threeunits);
  }
  .contact-main {
    width: 100%;
    align-items: flex-start;
  }
  .contact-heading {
    font-size: 28px;
  }
  .contact-paragraph {
    line-height: 24px;
  }
}
@media(max-width: 479px) {
  .contact-image {
    width: 227px;
    height: 317px;
  }
  .contact-image1 {
    width: 234px;
    height: 309px;
    align-self: center;
  }
}
